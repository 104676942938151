<template>
  <div
    class="chart-container"
    :style="dynamicSize ? {height: '100%'} : {}"
    ref="container"
  >
    <div v-if="busy" class="loading">
      <div>
        <i class="fa fa-refresh fa-spin"></i>
      </div>
    </div>
    <img
      v-if="generateImage"
      class="chart"
      ref="chartImage"
      :src="''"
      :style="{display: $store.getters.print ? 'block' : 'none'}"
    />
    <div
      class="chart html2canvas-ignore"
      data-html2canvas-ignore
      ref="chart"
      style="height: inherit; overflow: visible"
    ></div>
  </div>
</template>
<script>
import echarts from "echarts";
import {ResizeObserver as Polyfill} from "@juggle/resize-observer";
import "@/utils/echarts-themes/telemetria";

import {cloneDeep, isEqual, debounce} from "lodash";
const ResizeObserver = window.ResizeObserver || Polyfill;

const serieFilter = ($vm, serie) => {
  if (serie?.validation || serie.type == "pie") {
    let exp = "",
      value;
    serie.data = serie.data.filter((sample) => {
      exp =
        (serie.type == "pie" && !$vm.namedQuery
          ? sample.validation
          : serie.validation) ?? "";
      if (exp === "") return true;
      value = !isNaN(Number(sample.value))
        ? sample.value
        : sample?.value?.length > 1
        ? sample.value[1]
        : undefined;
      if (value !== undefined) {
        return $vm.$utils.isTrue(exp, {
          ...sample,
          $value: parseFloat(value),
          value: parseFloat(value)
        });
      }
      return false;
    });
  }
};

const serieEvaluation = ($vm, serie) => {
  if (serie.itemStyle.expression || serie.type == "pie") {
    serie.data.forEach((sample) => {
      if (sample.value === undefined) return;
      if (!isNaN(Number(sample.value))) {
        sample.initial = sample.value;
        if (serie.type == "pie" && !$vm.namedQuery) {
          // source is dataserie, therefore a single serie will have several data
          if (sample.expression) {
            sample.value = parseFloat(
              $vm.$root.$formatter.format({
                current_value: {
                  value: parseFloat(sample.value)
                },
                history: sample.history ||
                  serie?.history || {samples: [], stats: {}},
                template: sample.expression
              })
            );
          }
        } else if (serie.itemStyle.expression) {
          sample.value = $vm.$utils.evaluate(
            {
              ...sample,
              $value: parseFloat(sample.value)
            },
            serie.itemStyle.expression
          );
        }
        return;
      }
      if (sample.value === undefined || !sample.value.length > 1) return;
      sample.initial = sample.value[1] || 0;
      sample.value[1] = $vm.$utils.eval(serie.itemStyle.expression, {
        $value: sample.initial
      });
    });
  }
};

const serieConfig = ($vm, serie) => {
  if (serie.lineStyle?.waveForm) {
    switch (serie.lineStyle.waveForm) {
      case "square":
        serie.step = "end";
        serie.smooth = false;
        break;
      case "triangle":
        serie.step = false;
        serie.smooth = false;
        break;
      case "sin":
        serie.step = false;
        serie.smooth = true;
        break;
    }
  }
};

const serieArea = ($vm, serie) => {
  if (serie.type == "line") {
    var opacity = serie.itemStyle.areaOpacity;
    var c = $vm.$utils.hexToRgb(serie.itemStyle.color);
    if (!c) {
      c = $vm.$utils.hexToRgb($vm.$utils.rgbToHex(serie.itemStyle.color));
    }
    if (opacity !== undefined && Number(opacity) === 1) {
      serie.areaStyle = {color: serie.itemStyle.color};
    } else {
      serie.areaStyle = {
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: `rgb(${c.r},${c.g},${c.b},0.8)`
            },
            {
              offset: 1,
              color: `rgb(${c.r},${c.g},${c.b},0.1)`
            }
          ],
          global: false
        }
      };
    }
    if (opacity !== undefined && Number(opacity) >= 0 && Number(opacity) <= 1) {
      serie.areaStyle.opacity = Number(opacity);
    }
  }
};

const dftWidget = (small, printPreview) => ({
  tooltip: {
    trigger: "axis",
    axisPointer: {
      animation: false
    },
    formatter: null
  },
  legend: {
    type: "scroll"
  },
  toolbox: {
    show: !small && !printPreview,
    orient: "vertical",
    top: 30,
    feature: {
      restore: {},
      dataZoom: {
        title: {
          zoom: this.$t("area_zoom"),
          back: this.$t("back")
        },
        emphasis: {
          iconStyle: {
            textFill: "#fff",
            textBackgroundColor: "rgba(50,50,50,0.7)",
            textPadding: 5,
            textPosition: "left"
          }
        }
      }
    }
  },
  xAxis: {
    type: "time",
    axisLabel: {
      formatter: null
    }
  },
  yAxis: {
    axisLabel: {
      formatter: null
    }
  },
  grid: {
    left: "5%",
    right: "5%",
    bottom: small ? "5%" : "15%",
    top: "10%",
    containLabel: true
  },
  dataZoom: [
    {
      show: !small && !printPreview,
      type: "slider",
      labelFormatter: null,
      start: 0,
      end: 100
    },
    {
      show: !small && !printPreview,
      type: "inside",
      start: 0,
      end: 100
    }
  ],
  series: {
    type: "line",
    data: []
  }
});

const labelFormatter = ($vm, params, exp) => {
  let value = Array.isArray(params.value)
    ? params.value[params.value.length - 1]
    : params.value;
  if (!exp) return value;
  exp = exp
    .replace(/\{value\}/g, value ?? "")
    .replace(/\{name\}/g, params.name ?? "")
    .replace(/\{percent\}/g, params.percent ?? "");
  return $vm.$utils.sprintf(exp, value);
};

const axisProp = (axis, name) => {
  const vlr = (axis[name] ?? "") || "";
  return vlr == "auto" ? "" : vlr;
};

export {serieFilter, serieEvaluation, serieConfig};

export default {
  name: "EquipmentHistoryChartDisplay",
  data: function() {
    return {
      busy: true,
      isOneDay: false,
      passedMidnight: false,
      tooltipFontStyle: null,
      tooltipWordWrap: false
    };
  },
  props: {
    dataset: {
      type: Array,
      required: false,
      default: null
    },
    widgetOptions: {
      type: Object,
      required: false,
      default: function() {
        return null;
      }
    },
    generateImage: {
      type: Boolean,
      required: false,
      default: false
    },
    dynamicSize: {
      type: Boolean,
      default: false
    },
    svg: {
      type: Boolean,
      default: false
    },
    disconnection: {
      type: Object,
      required: false,
      default: () => null
    },
    namedQuery: {
      type: String,
      required: false,
      default: ""
    }
  },
  watch: {
    dataset: {
      handler(n, o) {
        // if (isEqual(n, o)) return;
        this._updateChart();
      },
      deep: true,
      immediate: true
    },
    widgetOptions: {
      handler(n, o) {
        if (isEqual(n, o)) return;
        this._updateChart();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    xScale() {
      return this?.widgetOptions?.xAxis?.xScale || {};
    },
    xScaleType() {
      return this?.xScale?.type || "value";
    },
    xAxisType() {
      return this?.widgetOptions?.xAxis?.type == "time" && this.namedQuery
        ? "category"
        : this?.widgetOptions?.xAxis?.type || "";
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    datasetIdList() {
      return this.$utils.distinct(
        (this.dataset || []).map(({data_id}) => parseInt(data_id))
      );
    },
    datasetDataList() {
      return (this.dataList || []).filter(
        ({id}) => this.datasetIdList.indexOf(parseInt(id)) >= 0
      );
    }
  },
  methods: {
    xAxleFormatter(value, index) {
      if (this.xScaleType == "expression" && this?.xScale?.expression) {
        let exp = (this?.xScale?.expression || "").split("|");
        exp[0] = exp[0] ?? "";
        exp[1] = exp[1] ?? "";
        exp[0] = exp[0] === "" ? "$value" : exp[0];
        if (this.xAxisType == "time") {
          // time series moment validation
          if (exp[0].indexOf("moment") == -1 && exp[1] !== "") {
            exp[0] = `moment(${exp[0]}).format("${exp[1]}")`;
            exp[1] = "";
          }
          exp[0] = exp[0].replace(/\$\{(\$value|value)\}/g, "value");
        }
        let entry = {
          index: index,
          value: value,
          $value: value
        };
        return this.$utils.evaluate(entry, exp.join("|"));
      }
      if (this.xAxisType == "time") {
        if (this.isOneDay) {
          // if current interval is up to one day
          let format = "LT"; // change format to hour and minute
          if (
            // if it's the first or the hour is from the next day
            index == 0 ||
            (moment(value).hours() == 0 && !this.passedMidnight)
          ) {
            format += " (l)"; // add the date to format
            // date in midnight already setted
            if (index != 0) this.passedMidnight = true;
          }
          // resets "midnight setted" when another formatting cycle begins
          if (index == 0 && this.passedMidnight) this.passedMidnight = false;
          return moment(value).format(format);
        } else return moment(value).format("L");
      } else {
        return value;
      }
    },
    yAxisFormatter(axis, value) {
      // const axis = (this._yAxis || [])[0] || {};
      const type = axis?.yScale?.type || "value";
      if (type == "text_list") {
        const items = (axis?.yScale?.textList?.items || []).sort(
          (a, b) => a.state - b.state
        );
        if (items?.length) {
          let state = items.find(({state}) => state == value);
          return (state && state?.label) || "";
        }
      } else if (type == "expression" && axis?.yScale?.expression) {
        /*
        var exp = `
          value >= 0 && value <= 1
            ? {
                "0": "Off",
                "1": "on"
              }[value]
            : ""
        `;
        */
        let exp = (axis?.yScale?.expression || "").split("|");
        exp[0] = exp[0] ?? "";
        exp[1] = exp[1] ?? "";
        exp[0] = exp[0] === "" ? "$value" : exp[0];
        let entry = {
          minimum: axisProp(axis, "min"),
          maximum: axisProp(axis, "max"),
          interval: axisProp(axis, "interval"),
          value: value,
          $value: value
        };
        let vlr = this.$utils.evaluate(entry, exp.join("|"));
        vlr = vlr === "" ? value : vlr;
        console.log(vlr);
        return vlr;
      }
      return value.toLocaleString();
    },
    formatedDataValue(dataId, value) {
      let data = (this.dataEntries || {})[dataId] ?? null;
      if (data && data?.value_format_type?.id) {
        let format = this.$root.$formatter.dataFormat(data);
        if (format.format_mask == "text_list") {
          return {
            type: "text_list",
            value: this.$root.$formatter.formatedDataValue(data, value)
          };
        } else if (
          format.format_mask == "duration" &&
          data.custom_format.indexOf("%") == -1
        ) {
          return {
            type: "value",
            value: moment
              .duration(parseInt(value), data.unity_label)
              .format(data.custom_format || undefined, {
                trim: false
              })
          };
        } else if (format.format_mask == "custom") {
          return {
            type: "value",
            value: this.$utils.sprintf(data.custom_format, parseFloat(value))
          };
        } else {
          var fmt = data.unity_label
            ? `${format.format_mask} ${data.unity_label}`
            : format.format_mask;
          return {
            type: "value",
            value: this.$utils.sprintf(fmt, parseFloat(value))
          };
        }
      }
      return {
        type: "value",
        value: value
      };
    },
    fmtDateTime: function(value) {
      return value
        ? this.$dt.format(value) || moment(value).format("LTS - ll")
        : value;
    },
    sliderFormatter(value) {
      if (this.isOneDay) return moment(value).format("LTS");
      else return moment(value).format("L");
    },
    insideFormatter(value) {
      if (this.isOneDay) return moment(value).format("LTS");
      else return moment(value).format("L");
    },
    createChart() {
      const small = window.innerWidth < 768;
      const printPreview = this.$store.getters.print;
      const self = this;
      let onlyPie = true;
      let hasData = false;
      //=======================
      this.dataEntries = {};
      let series = structuredClone(this.dataset || []);
      (this.dataset || []).filter(({data}) => {
        if (!(data || []).length || !data[0].data_id) return false;
        if (this.dataEntries[data[0].data_id]) return true;
        let DATA = this.dataList.find(
          ({id, type}) =>
            parseInt(id) === parseInt(data[0].data_id) && type !== "string"
        );
        if (DATA) {
          this.dataEntries[data[0].data_id] = DATA;
        }
      });
      this.updateContainerHeight();
      let maxPoints = 100; // max points displaying at once
      let names = {}; // serie name counter that avoids redundancies on same name
      this.yAxisUsage = {};
      let yAxisUsage = {};
      let yAxisIndex = -1;
      this._hiddenAtSeries = {
        legend: {},
        tooltip: {}
      };
      series.forEach((serie, ix) => {
        if (!serie?.data?.length) return;
        if (serie.type == "pie") {
          serie?.itemStyle?.color && delete serie?.itemStyle?.color;
          // if (!(serie?.showInLegend ?? true))
          //   this._hiddenAtSeries.legend[serie.name] = true;
          (serie.data || []).forEach((item) => {
            if (!(item?.showInLegend ?? true)) {
              this._hiddenAtSeries.legend[item.name] = true;
            }
          });
        } else {
          onlyPie = false;
          yAxisIndex = serie.yAxisIndex ?? 0;
          yAxisUsage[yAxisIndex] = yAxisUsage[yAxisIndex] || [];
          yAxisUsage[yAxisIndex].push(ix);
          this.yAxisUsage[serie.name] = yAxisIndex;
        }
        hasData = true;
        names[serie.name] = (names[serie.name] ?? -1) + 1;
        if (names[serie.name] > 0) {
          serie.name = `[${ix}] ${serie.name}`;
        }
        serieConfig(this, serie);
        if (serie?.itemStyle?.showArea) {
          serieArea(this, serie);
        }
        if (
          serie?.validationReadValue == undefined ||
          serie?.validationReadValue
        ) {
          // filter raw value first, then calculate the new value
          serieFilter(this, serie);
          if (serie?.itemStyle?.expressionSlot?.chart) {
            serieEvaluation(this, serie);
          }
        } else {
          // calculate the new value, and filter the calculated value
          if (serie?.itemStyle?.expressionSlot?.chart) {
            serieEvaluation(this, serie);
          }
          serieFilter(this, serie);
        }
        if (serie.animation && (printPreview || small)) {
          serie.animation = false;
        }
        if (!hasData && serie.data.length) {
          hasData = true;
        }
        // begin label
        if (serie?.label?.show) {
          serie.showSymbol = true;
          if (serie?.label?.show) {
            if (serie.label.formatter === "") {
              serie.label.formatter = null;
            } else {
              this._labelFormatters = this._labelFormatters || {};
              if (!this.namedQuery && serie.type == "pie") {
                // for this specific chart and source there will be only a single serie with several data items
                serie.data.forEach((item) => {
                  // dataLabelFormatterIndex: pieCfg.data.length)
                  if (item?.label?.show && item?.label?.dataLabelFormatter) {
                    item.label.formatter = (params) => {
                      return labelFormatter(
                        self,
                        params,
                        params.data.label.dataLabelFormatter ?? ""
                      );
                    };
                  }
                });
              } else {
                let exp = serie.label.formatter;
                this._labelFormatters[ix] = exp;
                serie.label.formatter = (params) => {
                  return labelFormatter(
                    self,
                    params,
                    self._labelFormatters[params.seriesIndex] ?? ""
                  );
                };
              }
            }
          }
        }

        // begin serie hidden at
        if (!(serie?.showInLegend ?? true))
          this._hiddenAtSeries.legend[serie.name] = true;
        if (!(serie?.showInTooltip ?? true))
          this._hiddenAtSeries.tooltip[ix] = true;

        // if (!ix || ix == 2) this._hiddenAtSeries.tooltip[ix] = true; // this line is for test purposes only:
        // end serie hidden
      });

      // let end = biggerThanMax ? 50 + percentange / 2 : 100;

      // specify chart configuration item and data
      let chartOptions = {
        title: {
          show: true,
          textStyle: {
            color: "#bcbcbc"
          },
          text: this.$t("no_data_found"),
          left: "center",
          top: "center"
        }
      };
      if (hasData || this.disconnection) {
        chartOptions =
          cloneDeep(this.widgetOptions) || dftWidget(small, printPreview);

        // tooltip formatter
        if (
          "formatter" in chartOptions?.tooltip &&
          !chartOptions.tooltip.formatter
        ) {
          chartOptions.tooltip.formatter = this.tooltipFormatter;
        }
        if (onlyPie) {
          // OPTIONS that makes sense for time series
          delete chartOptions.xAxis;
          delete chartOptions.yAxis;
          delete chartOptions.dataZoom;
          delete chartOptions.toolbox;
          delete chartOptions.tooltip;
          if (series.length > 1) {
            chartOptions.title = [chartOptions.title].concat(
              series
                .filter(({data, type}) => data && data.length && type == "pie")
                .map((i) => {
                  let top = Number(this.$utils.onlyNumbers(i.center[1]));
                  let radius = Number(this.$utils.onlyNumbers(i.radius));
                  top = top - radius / 2 - 5;
                  return {
                    text: i.name,
                    left: i.center[0],
                    top: `${Math.floor(top)}%`,
                    textAlign: "center"
                  };
                })
            );
          }
        } else {
          // OPTIONS that makes sense for time series
          // BEGIN X Axis
          chartOptions.xAxis = chartOptions.xAxis || {};
          chartOptions.xAxis.show = (chartOptions.xAxis?.show ?? true) || false; // make sure to cover "" and undefined
          if (chartOptions.xAxis.show) {
            chartOptions.xAxis.type = this.xAxisType; // force category if applicable
            chartOptions.xAxis.axisLabel = chartOptions.xAxis.axisLabel || {};
            chartOptions.xAxis.axisLabel.formatter = this.xAxleFormatter;
          }
          // END X Axis

          // BEGIN Y Axis
          chartOptions.yAxis = chartOptions.yAxis || {};
          if (!Array.isArray(chartOptions.yAxis)) {
            chartOptions.yAxis = [chartOptions.yAxis];
          }
          chartOptions.yAxis = chartOptions.yAxis.map((item, ix) => {
            yAxisUsage && delete yAxisUsage[ix];
            // backward compatibility issues
            if (
              (item?.axisLabel?.formatter &&
                typeof item.axisLabel.formatter === "function") ||
              (item.axisLabel?.textStyle?.color &&
                typeof item.axisLabel?.textStyle?.color === "function")
            ) {
              return item;
            }
            let yAxis = structuredClone(item);
            yAxis.show = (yAxis?.show ?? true) || false; // make sure to cover "" and undefined
            yAxis.restore_show = yAxis.show;
            ["min", "max", "interval"].forEach((p) => {
              if ((yAxis || {})[p] === "auto" || (yAxis || {})[p] === "")
                delete yAxis[p];
            });
            if (yAxis.show) {
              yAxis.axisLabel = yAxis.axisLabel || {};
              yAxis.axisLabel.formatter = function(value) {
                return this.fmt(this.cfg, value);
              }.bind({
                cfg: yAxis,
                fmt: this.yAxisFormatter
              });
              if (yAxis.name && yAxis.dynamicColor && yAxis.axisLabel.color) {
                yAxis.nameTextStyle = {
                  color: yAxis.axisLabel.color
                };
              }
            } else {
              delete yAxis.axisLabel;
            }
            return yAxis;
          });
          this._yAxis = chartOptions.yAxis;
          // validate if there are any serie pointing to a invalid yAxis scale
          (series || []).length &&
            Object.entries(yAxisUsage).forEach((i) => {
              i[1].forEach((serieIx) => {
                series[serieIx].yAxisIndex = 0;
                this.yAxisUsage[series[serieIx].name] = 0;
              });
            });
          // END Y Axis

          // BEGIN Zoom formatters
          if (chartOptions?.dataZoom?.length) {
            // find series with bigger data length
            let maxLength = series.reduce(
                (max, {data}) => (data?.length > max ? data.length : max),
                0
              ),
              biggerThanMax = false, // biggerThanMax = maxLength > maxPoints,
              percentange = (maxLength && (maxPoints / maxLength) * 100) || 0;

            let start = biggerThanMax ? 50 - percentange / 2 : 0;
            let end = biggerThanMax ? 50 + percentange / 2 : 100;
            // let start = biggerThanMax ? 50 - percentange / 2 : 25;
            (chartOptions.dataZoom || []).forEach((i) => {
              i.start = start;
              i.end = end;
              if (i.type == "slider") {
                if (i.show && (printPreview || !hasData)) {
                  i.show = false;
                }
                if ("labelFormatter" in i && !i.labelFormatter) {
                  i.labelFormatter = this.sliderFormatter;
                }
                if (!i.filterMode) {
                  i.filterMode = "none";
                }
              } else if (i.type == "inside") {
                if (i.show && printPreview) {
                  i.show = false;
                }
                if ("labelFormatter" in i && !i.labelFormatter) {
                  i.labelFormatter = this.sliderFormatter;
                }
                if (!i.filterMode) {
                  i.filterMode = "none";
                }
              }
            });
          }
          // toolbox
          if (chartOptions?.toolbox) {
            if (chartOptions.toolbox.show && (printPreview || !hasData)) {
              chartOptions.toolbox.show = false;
            }
          }
          // tooltip
          if (chartOptions?.tooltip) {
            if (
              chartOptions?.tooltip?.axisPointer?.animation &&
              (printPreview || !hasData)
            ) {
              chartOptions.tooltip.axisPointer.animation = false;
            }
          }
          this.initOneDay(series);
        }

        // legend
        if (chartOptions?.legend) {
          chartOptions.legend.show = chartOptions?.legend?.show ?? true;
          if (chartOptions?.legend?.show && !hasData) {
            chartOptions.legend.show = false;
          }
          if (chartOptions.legend.show) {
            const nHidden = Object.keys(this._hiddenAtSeries.legend).length;
            if (onlyPie) {
              series.forEach((serie) => {
                (serie?.data || []).forEach((i) => {
                  if (this._hiddenAtSeries.legend[i.name]) return;
                  chartOptions.legend.data = chartOptions.legend.data || [];
                  chartOptions.legend.data.push({
                    name: i.name,
                    ...(chartOptions.legend.dynamicColor && i?.label?.color
                      ? {textStyle: {color: i?.label?.color}}
                      : null)
                  });
                });
              });
            } else {
              if (nHidden == series.length) {
                chartOptions.legend.show = false;
              } else {
                series.forEach((i) => {
                  if (this._hiddenAtSeries.legend[i.name]) return;
                  chartOptions.legend.data = chartOptions.legend.data || [];
                  chartOptions.legend.data.push({
                    name: i.name,
                    ...(chartOptions.legend.dynamicColor
                      ? {textStyle: {color: i.itemStyle.color}}
                      : null)
                  });
                });
              }
            }
          }
        }

        chartOptions.series = series;
        chartOptions.height = "auto";

        // BEGIN DISCONNECTION
        if (this.disconnection && !onlyPie) {
          (chartOptions.series || []).forEach((serie) => {
            serie.zlevel = 1;
            if (this.disconnection.dataLine != "trend") {
              for (var connId in this.disconnection.items || {}) {
                (this.disconnection.items[connId] || []).forEach((i) => {
                  if (
                    i.ini > this.disconnection.ini &&
                    i.end < this.disconnection.end
                  ) {
                    serie.data.push({
                      name: i.ini,
                      data_id: serie.data[0].data_id,
                      value: [i.ini, null]
                    });
                  }
                });
              }
              serie.data = serie.data.sort((a, b) =>
                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
              );
            }
          });
          for (var connId in this.disconnection.items || {}) {
            let v = [];
            (this.disconnection.items[connId] || []).forEach((i) => {
              v.push([i.ini, i.value]);
              v.push([i.end, i.value]);
              v.push([
                new Date(new Date(i.end).getTime() + 1000).toISOString(),
                null
              ]);
            });
            v.push([
              new Date(
                new Date(this.disconnection.end).getTime() - 1000
              ).toISOString(),
              null
            ]);
            var shadow = {
              name: this.$t("disconnection"),
              zlevel: 2,
              data: v,
              type: "line",
              smooth: false,
              itemStyle: {
                color: this?.disconnection?.color ?? "#FFF",
                enabled: true
              },
              areaStyle: {
                opacity: 1,
                color: this?.disconnection?.color ?? "#FFF"
              },
              showSymbol: false,
              step: false,
              symbolSize: 1,
              validation: "",
              validationReadValue: true,
              fillOpacity: 1
            };
            chartOptions.series.push(shadow);
          }
        }
        // END DISCONNECTION
      }
      // Chart creation
      let el = this.$refs?.chart || null;
      if (el) {
        // workaround for echart text align bug
        if (!chartOptions?.title?.left && chartOptions?.title?.textAlign) {
          chartOptions.title.left = chartOptions?.title?.textAlign || "center";
        }
        let prv = this.getChart();
        if (prv) {
          this.prvTip = {};
          prv.clear();
        }
        var chart = echarts.init(el, null, {
          renderer: this.svg ? "svg" : "canvas"
        });
        if (this.generateImage) {
          chart.on("finished", this.onFinished);
        }
        if (hasData || this.disconnection) {
          if (chartOptions.tooltip) {
            this._tooltipLastValue = chartOptions.tooltip.last_value ?? true; // not reactive
            chart.on("hideTip", () => {
              this.prvTip = {};
            });
            if (chartOptions.tooltip.config) {
              this.tooltipSetup(chartOptions);
            }
          }
          chart.on("legendselectchanged", (params) => {
            this.updateYScale(params.selected);
            this.updateToolTip(params.selected);
          });
          // chartOptions.series[0].areaStyle.color = "#4b94bf";
          // chartOptions.series[0].areaStyle.opacity = 1;
          chart.setOption(chartOptions);
          this.$nextTick(() => {
            if (chartOptions?.tooltip?.config?.open_on_startup) {
              this.toggleToolTip();
            }
          });
        } else {
          chart.setOption(chartOptions);
        }
        this.$root.$emit("panel:resized");
      }
      this.busy = false;
    },
    initOneDay(series) {
      if (!(series || []).length) return;
      var iDt = new Date().getTime() + 1;
      var eDt = 0;
      var dts /* date string */, dt /* date */, first, last;
      series
        .filter((s) => s.type == "line" || s.type == "bar")
        .forEach(({data}) => {
          if (data.length) {
            first = data[0];
            last = data[data.length - 1];
            dts =
              (first.value && first.value.length == 2 && first.value[0]) ||
              first[0] ||
              first.name;
            // dt = new Date(dts).getTime();
            dt = dts;
            if (dt <= iDt) iDt = dt;
            dts =
              (last.value && last.value.length == 2 && last.value[0]) ||
              last[0] ||
              last.name;
            // dt = new Date(dts).getTime();
            dt = dts;
            if (dt >= eDt) eDt = dt;
          }
        });
      this.calcOneDay(iDt, eDt);
    },
    calcOneDay(start, end) {
      let diff = moment(end).diff(start, "hours");
      if (diff <= 24) {
        this.isOneDay = true;
      } else {
        this.isOneDay = false;
      }
    },
    onDataZoom(event) {
      // check if event was fired by slider data zoom
      let start, end;
      if (event.dataZoomId) {
        ({start, end} = getStartAndEndFrom.call(this, event.start, event.end));
      } else if (event.batch[0].dataZoomId.includes("series")) {
        // if it's from inside data zoom
        ({start, end} = getStartAndEndFrom.call(
          this,
          event.batch[0].start,
          event.batch[0].end
        ));
      } else {
        // if it's from area data zoom
        start = event.batch[0].startValue;
        end = event.batch[0].endValue;
      }

      // let diff = moment(end).diff(start, "hours");
      // if (diff <= 24) {
      //   this.isOneDay = true;
      // } else {
      //   this.isOneDay = false;
      // }
      this.calcOneDay(start, end);

      // convert start and end from percentage to absolute date value
      function getStartAndEndFrom(percentageStart, percentageEnd) {
        let firstDate = moment(
          this.dataset[0].data[this.dataset[0].data.length - 1].value[0]
        );
        let lastDate = moment(this.dataset[0].data[0].value[0]);
        let duration = moment.duration(lastDate.diff(firstDate));

        let start = firstDate
          .clone()
          .add(duration.asMilliseconds() * (percentageStart / 100), "ms");
        let end = firstDate
          .clone()
          .add(duration.asMilliseconds() * (percentageEnd / 100), "ms");
        return {start, end};
      }
    },
    getChart() {
      let el = this.$refs?.chart || null;
      return el ? echarts.getInstanceByDom(el) : null;
    },
    onFinished() {
      if (
        !(this.$refs?.container?.getBoundingClientRect()?.height || 0) ||
        !this.$refs.chart ||
        !this.$refs.chartImage
      ) {
        return;
      }
      const chart = this.getChart();
      let canvas = this.$refs.chart.getElementsByTagName("canvas")[0];
      if (chart) {
        this.$refs.chartImage.src = chart.getDataURL({
          backgroundColor: "#fff",
          type: "jpeg" // Important since png is throwing error while printing
        });
        if (this.$store.getters.print) {
          canvas.style.display = "none";
        } else {
          canvas.style.display = "block";
        }
      } else {
        this.$refs.chartImage.src = "";
        canvas.style.display = "block";
      }
    },
    updateContainerHeight() {
      if (this.$refs.container && !this.dynamicSize) {
        this.$refs.container.style.height =
          this.$parent.$el.clientHeight -
          (this.$refs.container.getBoundingClientRect().top -
            this.$parent.$el.getBoundingClientRect().top) +
          "px";
      }
    },
    panelChangeMonitor() {
      if (this._SizeAnalizer) return;

      this._height = 0; // not reactive
      this._width = 0; // not reactive
      this._skip = false; // not reactive

      this._SizeAnalizer = debounce((els) => {
        if (this.busy || this._skip) return;
        this._skip = true; // not reactive
        // let el = els && els.length ? els[0] : null;
        // let rc = el && el?.target?.getBoundingClientRect();
        let rc =
          (this.$parent && this.$parent.$el.getBoundingClientRect()) || null;
        let h = parseInt((rc && rc?.height) || 0);
        let w = parseInt((rc && rc?.width) || 0);
        if (h != this._height || w != this._width) {
          this._height = h;
          this._width = w;
          if (this._height && this._width) {
            let chart = this.getChart();
            if (chart) {
              this.updateContainerHeight();
              this.$nextTick(() => {
                chart.resize();
                this._skip = false;
              });
              return;
            }
          }
        }
        this._skip = false;
      }, 200);

      const sizeWatcher = new ResizeObserver(this._SizeAnalizer);
      let _p = this.$refs.container;
      if (this.dynamicSize) {
        _p = _p.parentElement;
      } else {
        while (!_p.classList.contains("inner-panel")) {
          _p = _p.parentElement;
          if (!_p) break;
        }
      }
      if (_p) {
        sizeWatcher.observe(_p);
      }
    },
    tooltipSetup(chartOptions) {
      const self = this;
      let tooltip = chartOptions.tooltip;
      if (!tooltip || !tooltip.config) return;
      if (tooltip.config.position_enabled) {
        // tooltip.appendToBody = true;
        const pos = (tooltip.config.type == "preset" &&
          tooltip.config.preset_value) || [
          `${tooltip.config.manual_value_x}${
            tooltip.config.manual_unit == "%" ? "%" : ""
          }`,
          `${tooltip.config.manual_value_y}${
            tooltip.config.manual_unit == "%" ? "%" : ""
          }`
        ];
        tooltip.position = function(point, params, dom, rect, size) {
          // console.log(size);
          if (pos == "top_right") {
            self.ttpos = [size.viewSize[0] - size.contentSize[0] - 15, 0];
          } else if (pos == "top_left") {
            self.ttpos = [10, 0];
          } else if (pos == "bottom_right") {
            self.ttpos = [
              size.viewSize[0] - size.contentSize[0] - 5,
              size.viewSize[1] - size.contentSize[1] - 10
            ];
          } else if (pos == "bottom_left") {
            self.ttpos = [10, size.viewSize[1] - size.contentSize[1] - 10];
          } else if (pos == "bottom_center") {
            self.ttpos = [
              size.viewSize[0] / 2 - size.contentSize[0] / 2,
              size.viewSize[1] - size.contentSize[1] - 10
            ];
          } else if (pos == "top_center") {
            self.ttpos = [size.viewSize[0] / 2 - size.contentSize[0] / 2, 0];
          } else if (typeof pos == "object") {
            self.ttpos = [
              pos[0] === "" || pos[0] === "%" ? point[0] : pos[0],
              pos[1] === "" || pos[1] === "%" ? point[1] : pos[1]
            ];
          } else {
            self.ttpos = point;
          }
          // Add any offset
          if (tooltip.config.type == "preset") {
            self.ttpos[0] += parseInt(tooltip.config.manual_value_x);
            self.ttpos[1] += parseInt(tooltip.config.manual_value_y);
          }
          return self.ttpos;
        };
        //
      }
      // font configuration
      let cssvars = null;
      if (tooltip.config.font) {
        cssvars = [];
        for (var p in tooltip.config.font) {
          cssvars.push(`${p}: ${tooltip.config.font[p]}`);
        }
      }
      if (tooltip.config.line_height) {
        cssvars = cssvars || [];
        cssvars.push(`line-height: ${tooltip.config.line_height}`);
      }
      this.$set(this, "tooltipFontStyle", (cssvars && cssvars.join(";")) || "");
      // tooltip word wrap
      this.tooltipWordWrap = tooltip.config.word_wrap;
      // keep it visible
      if (tooltip?.config?.keep_visible) {
        tooltip.alwaysShowContent = true;
      }
      tooltip.confine = true;
      tooltip.enterable = true;
      tooltip.extraCssText = "overflow-y: auto; max-height: 90%;";
    },
    toggleToolTip() {
      const chart = this.getChart();
      if (!chart) return;
      this.isTooltipVisible()
        ? this.hideToolTip(chart)
        : this.showToolTip(chart);
    },
    showToolTip(chart, ix) {
      let $calendar = document.querySelector("div.show-calendar");
      if ($calendar && $calendar.checkVisibility()) return;
      chart.dispatchAction({
        type: "downplay",
        seriesIndex: ix || 0,
        dataIndex: 0
      });
      chart.dispatchAction({
        type: "highlight",
        seriesIndex: ix || 0,
        dataIndex: 0
      });
      chart.dispatchAction({
        type: "showTip",
        seriesIndex: ix || 0,
        dataIndex: 0
      });
    },
    hideToolTip(chart) {
      chart.dispatchAction({
        type: "hideTip"
      });
    },
    updateYScale(selected) {
      const chart = this.getChart();
      if (!chart) return;
      let opt = chart.getOption();
      if (!opt || !opt.yAxis.length) return;
      let hide = {};
      let show = {};
      for (var iname in selected) {
        if (this.yAxisUsage[iname] !== undefined) {
          if (selected[iname]) {
            show[this.yAxisUsage[iname]] = true;
          } else {
            hide[this.yAxisUsage[iname]] = true;
          }
        }
      }
      opt.yAxis.forEach((o, ix) => {
        if (!o.restore_show) return;
        o.show = hide[ix] && !show[ix] ? false : o.restore_show;
      });
      chart.setOption(opt);
    },
    updateToolTip(selected) {
      let nVisible = Object.values(selected).filter((i) => i).length;
      this.prvTip = {};
      const chart = this.getChart();
      if (!chart) return;
      let opt = chart.getOption();
      if (!opt) return;
      let tooltip = opt.tooltip && opt.tooltip.length && opt.tooltip[0];
      if (tooltip && tooltip?.config?.keep_visible) {
        if (!nVisible) {
          tooltip.alwaysShowContent = false;
          chart.setOption(opt);
          this.hideToolTip(chart);
          return;
        }
        if (!tooltip.alwaysShowContent) {
          tooltip.alwaysShowContent = true;
          chart.setOption(opt);
        }
        if (this.isTooltipVisible()) {
          chart.dispatchAction({type: "hideTip"});
        }
        for (var i = 0; i < opt.series.length; i++) this.showToolTip(chart, i);
      }
    },
    isTooltipVisible() {
      if (!this.getChart()) return undefined;
      let el = this.$el.querySelector(".tooltip-data");
      return (
        (el && el.parentNode && el.parentNode.style.display == "block") || false
      );
    },
    tooltipFormatter(params) {
      let $calendar = document.querySelector("div.show-calendar");
      if ($calendar && $calendar.checkVisibility()) return;
      let self = this;
      this.prvTip = this._tooltipLastValue === false ? {} : this.prvTip || {};
      // let content = moment(params[0].name).format("LTS - ll");
      var disconnectionPoint = (params || []).find(
        ({seriesName, data}) =>
          seriesName == this.$t("disconnection") && data && data[1] !== null
      );
      if (disconnectionPoint) {
        this.prvTip = {};
        let dt = disconnectionPoint.value[0];
        let fmt = "";
        for (var connId in this.disconnection.items || {}) {
          (this.disconnection.items[connId] || []).forEach(
            ({ini, end, connectorName}) => {
              if (dt >= ini && dt <= end && !fmt) {
                var mI = moment(ini);
                var mE = moment(end);
                var mD = mE.diff(mI);
                fmt = `${(connectorName || "").toUpperCase()}<br/>\
              ${this.$tc("disconnected", 1)} - ${this.$t(
                  "duration"
                )}: ${moment
                  .duration(mD / 1000, "seconds")
                  .format("hh[h]:mm[m]:ss[s]")}<br/>\
              ${mI.format("L LTS")} - ${mE.format("L LTS")}<br/>\
              `;
              }
            }
          );
        }
        return fmt || this.$tc("disconnected", 1);
      }
      let content = [];
      let found = {};
      let fmt = null;
      let itemClass = this.tooltipWordWrap
        ? "tooltip-data tooltip-data-inline"
        : "tooltip-data";
      let minAxleValue;
      params
        .filter(({seriesName}) => seriesName !== this.$t("disconnection"))
        .forEach((series) => {
          if ((this?._hiddenAtSeries?.tooltip || {})[series.seriesIndex])
            return;
          let value = "";
          let initial = ""; //
          if (series?.data?.data_id) {
            fmt = this.formatedDataValue(
              series?.data?.data_id,
              series.value[1]
            );
            value = fmt.value;
            if ("initial" in (series?.data || {})) {
              fmt = this.formatedDataValue(
                series?.data?.data_id,
                series?.data.initial || 0
              );
              if (fmt.type == "text_list") {
                value = series?.data.initial;
              }
              initial = fmt.value;
              if (initial == value) initial = "";
            }
          } else {
            value =
              (series?.data?.format &&
                ["text_list", "duration", "custom"].indexOf(
                  series.data.format
                ) == -1) ||
              ""
                ? self.$utils.sprintf(series.data.format, series.value[1])
                : series.value[1];
          }
          if (!content.length) {
            content.push(
              `<div class="tooltip-data" style="margin-bottom:4px;font-size:80%">${
                this.namedQuery
                  ? series.value[0]
                  : this.fmtDateTime(series.value[0])
              }</div>`
            );
          }
          var serieName =
            series.seriesName.slice(0, 30) +
            (series.seriesName.length > 30 ? "..." : "");
          var fmtValue = `${value}${
            initial === "" ? "" : " (" + initial + ")"
          }`;
          content.push(
            `<div class="${itemClass}" style='${this.tooltipFontStyle}'><span class='tooltip-data-icon' style="background-color:${series.color};"></span><span class='tooltip-data-label'>${serieName}: ${fmtValue}</span></div>`
          );
          if (series?.data?.data_id) {
            minAxleValue =
              minAxleValue === undefined
                ? series.axisValue
                : series.axisValue < minAxleValue
                ? series.axisValue
                : minAxleValue;
            found[series?.data?.data_id] = true;
            this.prvTip[series?.data?.data_id] = {
              color: series.color,
              name: serieName,
              value: fmtValue,
              axisValue: series.axisValue
            };
          }
        });

      for (var data_id in this.prvTip) {
        if (!found[data_id] && this.prvTip[data_id].axisValue < minAxleValue) {
          var entry = this.prvTip[data_id];
          content.push(
            `<div class="${itemClass}" style='${this.tooltipFontStyle}'><span class='tooltip-data-icon' style="background-color:${entry.color};"></span><span class='tooltip-data-label'>${entry.name}: ${entry.value}</span></div>`
          );
        }
      }
      return content.join("");
    },
    onDateRangeEvent($event) {
      if ($event == "show" && this.isTooltipVisible() && this.$refs.container) {
        this.createChart();
      }
    }
  },
  activated() {
    let chart = this.getChart();
    if (chart) {
      this.updateContainerHeight();
      chart.resize();
    }
  },
  mounted() {
    this.panelChangeMonitor();
    this._updateChart();
    this.$root.$on("chart:toggleToolTip", this.toggleToolTip);
    this.$root.$on("dateRangePickerEvent", this.onDateRangeEvent);
  },
  beforeDestroy() {
    this.$root.$off("dateRangePickerEvent", this.onDateRangeEvent);
    this.$root.$off("chart:toggleToolTip", this.toggleToolTip);
  },
  beforeCreate() {
    this._updateChart = debounce(() => {
      if (this.$refs.container) {
        this.createChart();
      }
    }, 500);
  }
};
</script>

<style scoped>
div.chart-container {
  width: 100%;
  height: inherit;
}

.echarts {
  margin: 0;
  width: 100%;
  height: inherit;
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-content: center;
  height: inherit;
  font-size: 20pt;
}

.loading > div {
  flex: 1 1 inherit;
  align-self: center;
}

@media screen {
  .no-screen {
    display: none;
  }
}
@media print {
  .no-print {
    display: none;
  }
}
</style>

<style>
.chart > * {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.chart > *::-webkit-scrollbar {
  display: none;
}
.tooltip-data {
  font-size: 80%;
  line-height: 1.1em;
}

.tooltip-data-inline {
  display: inline-block;
  margin-right: 12px;
}

.tooltip-data > .tooltip-data-icon {
  font-size: 85%;
  display: inline-block;
  margin-right: 4px;
  border-radius: 8px;
  width: 8px;
  height: 8px;
}

.tooltip-data > .tooltip-data-label {
  vertical-align: middle;
}
</style>
